import stake from "../../assets/Images/stake.gif";
import title from "../../assets/Images/title.png";
import { useEffect, useState } from "react";
import stakeAbi from "../../contracts/stake_abi.json";
import nftAbi from "../../contracts/nft_abi.json";
import { ethers } from "ethers";
import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import Swal from "sweetalert2";
import Loading from "../UI/Loading";

const Stake = () => {
  const { data: signer } = useSigner();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();

  const [nftBalance, setNftBalance] = useState(0);
  const [rewards, setRewards] = useState(0);
  const [checkRewardId, setCheckRewardId] = useState();
  const [idCollection, setIdCollection] = useState([]);
  const [loading, setLoading] = useState(false);

  const nftAddress = "0x2FEb0F83e42140293CEa1cFcAa75B163fB2eea16";
  const stakeAddress = "0x1A2d7Eea1ac51cC146D9B3c5B2E171795b140aE2";

  const staticProvider = new ethers.providers.JsonRpcProvider(
    "https://rpc.ankr.com/eth"
  );

  const readStakeContract = new ethers.Contract(
    stakeAddress,
    stakeAbi,
    staticProvider
  );

  const readNftContract = new ethers.Contract(
    nftAddress,
    nftAbi,
    staticProvider
  );
  const stakeContract = new ethers.Contract(stakeAddress, stakeAbi, signer);

  const connectWallet = () => {
    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  const checkIsStaked = async () => {
    const result = await readStakeContract.tokenIdIsStaked(
      nftAddress,
      checkRewardId
    );

    !result
      ? Swal.fire("Sorry!", "This token is not staked", "error")
      : Swal.fire("Let's Go!", "This token is staked", "success");
  };

  const checkReward = async () => {
    const isStaked = await readStakeContract.tokenIdIsStaked(
      nftAddress,
      checkRewardId
    );

    if (!isStaked) {
      Swal.fire("Sorry!", "This token is not staked", "error");
      setRewards(0);
    }

    const result = await readStakeContract.pendingRewardNew(
      nftAddress,
      checkRewardId
    );

    setRewards(Number(ethers.utils.formatEther(result)).toFixed(2));
  };

  const claimReward = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    try {
      setLoading(true);
      const claim = await stakeContract.claimRewardsNew(
        nftAddress,
        checkRewardId
      );
      await claim.wait();

      Swal.fire(
        "Reward Claimed!",
        `You claimed rewards for token ${checkRewardId}`,
        "success"
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleReward = (e) => {
    setCheckRewardId(e.target.value);
  };

  const handleStakeInput = (e) => {
    const value = e.target.value;
    setIdCollection(value.split(","));
  };

  const stakeNfts = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    if (idCollection.length === 0) {
      return Swal.fire(
        "Empty Input",
        `Please, input token IDs to stake`,
        "warning"
      );
    }

    try {
      setLoading(true);
      const stakeNfts = await stakeContract.stakeMultiple(
        nftAddress,
        idCollection.map((item) => Number(item))
      );

      await stakeNfts.wait();
      Swal.fire(
        "NFTs Staked!",
        `You staked the following Ids: ${idCollection}`,
        "success"
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const unstakeNfts = async () => {
    if (signer === undefined) {
      connectWallet();
    }

    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }

    if (idCollection.length === 0) {
      return Swal.fire(
        "Empty Input",
        `Please, input token IDs to unstake`,
        "warning"
      );
    }

    try {
      setLoading(true);
      const stakeNfts = await stakeContract.unstakeMultiple(
        nftAddress,
        idCollection.map((item) => Number(item))
      );

      await stakeNfts.wait();

      Swal.fire(
        "NFTs Unstaked!",
        `You unstaked the following IDs: ${idCollection}`,
        "success"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const rawBalance = await readNftContract.balanceOf(address);
      const balance = ethers.utils.formatUnits(rawBalance, 0);
      setNftBalance(balance);
    };

    fetchData();
  }, []);

  return (
    <section className="w-full min-h-[70vh] flex bg-[#0C0C0C] justify-center pt-1 px-1">
      <div className="max-w-screen-2xl flex flex-col justify-center items-center w-full p-2 rounded-xl">
        {loading && <Loading />}

        <h2 className="mt-40">
          <img
            src={title}
            alt=""
          />
        </h2>
        <div className="mb-20 mt-10">
          <img
            src={stake}
            alt=""
          />
        </div>

        {/* input for stake/unstake */}
        <div className="flex justify-center items-center gap-4 flex-wrap">
          <input
            onChange={handleStakeInput}
            type="text"
            className="bg-[#ffffff] w-24 p-2 text-black text-center outline-none rounded-xl h-10 mb-3"
          />

          <h2 className="font-semibold text-center text-xl mb-2 text-white">
            Enter your oDD BOi token iD
          </h2>
        </div>

        <h2 className="font-semibold text-center text-xl mb-2 text-[#FFB800]">
          &#x2022; Your token iD is your BOi number minus 1
        </h2>

        {/* buttons */}
        <div className="flex gap-2 mb-3">
          <button
            onClick={stakeNfts}
            className="bg-[#DA9C08] uppercase text-white w-36 rounded-tl-none px-2 h-14 font-bold text-2xl rounded-lg flex justify-center items-center"
          >
            Snooze
          </button>
          <button
            onClick={unstakeNfts}
            className="bg-[#DA9C08] uppercase text-white w-36 rounded-tl-none px-2 h-14 font-bold text-2xl rounded-lg flex justify-center items-center"
          >
            Unstake
          </button>
        </div>

        {/* Instructions */}
        <div className="flex text-xl mb-5 text-[#A5A5A5] flex-col gap-10 justify-center mt-10 flex-wrap">
          <div className="flex flex-col items-center">
            <ul className="text-center sm:text-left gap-2 flex flex-col">
              <li>
                &#x2022; <span className="font-bold">Snooze</span> is a
                soft-staking mechanic
              </li>
              <li>&#x2022; Your oDD NFT remains in your wallet</li>
              <li>&#x2022; Earn 10 $oDD/day as a reward for snoozing</li>
            </ul>
          </div>

          <div className="flex flex-col">
            <ul className="text-center sm:text-left gap-2 flex flex-col">
              <li>
                &#x2022; <span className="font-bold">Unsnooze</span> unstakes
                your oDD NFT
              </li>

              <li>&#x2022; You will stop earning daily $oDD rewards</li>
            </ul>
          </div>
        </div>

        {/* card */}
        <div className="flex flex-col mt-5 items-center max-w-md w-full bg-[#171717] p-3 rounded-md mb-20">
          <h2 className="text-2xl text-white font-bold mb-1 mt-10">You own</h2>

          <div className="flex flex-col gap-2 mb-6">
            <span className="text-lg text-white h-16 flex justify-center items-center min-w-[200px] w-full font-bold bg-[#707070] p-1 px-2 rounded-lg">
              <span className="text-[#00E392] mr-2"> {rewards} </span> $oDD
            </span>

            <span className="text-lg text-white h-16 flex justify-center items-center min-w-[200px] w-full font-bold bg-[#707070] p-1 px-2 rounded-lg">
              <span className="text-[#00E392] mr-2"> {nftBalance} </span> NFTs
            </span>
          </div>

          <div className="flex gap-2">
            {/* Check rewards */}
            <div className="flex flex-col items-center mt-2 gap-1">
              <button
                onClick={checkReward}
                className="bg-[#DA9C08] w-36 p-4 rounded-lg font-bold text-white"
              >
                Check Reward
              </button>
            </div>

            {/* Is staked */}
            <div className="flex flex-col items-center mt-2 gap-1">
              <button
                onClick={checkIsStaked}
                className="bg-[#DA9C08] w-36 p-4 rounded-lg font-bold text-white"
              >
                Is Staked?
              </button>
            </div>
          </div>

          <div className="flex flex-col  items-center mb-14">
            <div className="flex justify-end items-end gap-2 mt-2">
              <input
                onChange={handleReward}
                type="number"
                min={0}
                className="bg-[#e6e6e6] w-36 outline-none appearance-none text-black text-center rounded-lg h-14"
              />
              <button
                onClick={claimReward}
                className="bg-[#DA9C08] w-36 p-4 rounded-lg font-bold text-white"
              >
                Claim Reward
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stake;
