import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import CheckWallet from "./components/Pages/CheckWallet";
import Mint from "./components/Pages/Mint";
import Stake from "./components/Pages/Stake";
import Timer from "./components/Pages/Timer";
import { useState } from "react";
import { ethers } from "ethers";
import nftAbi from "./contracts/nft_abi.json";
import { useEffect } from "react";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route
            path="/"
            element={<Stake />}
          />

          {/* <Route
            path="/checkWallet"
            element={<CheckWallet />}
          />

          <Route
            path="/mint"
            element={<Mint />}
          />

          <Route
            path="/stake"
            element={<Stake />}
          /> */}
        </Routes>

        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
