import discord from "../../assets/Icons/discord.svg";
import twitter from "../../assets/Icons/twitter.svg";
import openSea from "../../assets/Icons/opensea.svg";

const Footer = () => {
  return (
    <footer className="w-full text-center text-white bg-black flex justify-center">
      <div className="max-w-screen-2xl w-full py-8 flex flex-col items-center">
        <ul className="flex gap-10 mb-6">
          <a
            href="https://discord.com/invite/oddverse"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <img
                src={discord}
                alt="discord"
                className="w-12"
              />
            </li>
          </a>

          <a
            href="https://twitter.com/oddboi_nft"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <img
                src={twitter}
                alt="twitter"
                className="w-12"
              />
            </li>
          </a>

          <a
            href="https://opensea.io/collection/odd-boi-official"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <img
                src={openSea}
                alt="openSea"
                className="w-12"
              />
            </li>
          </a>
        </ul>

        <ul className="flex text-[18px] font-bold gap-7 sm:gap-10 justify-around">
          <a
            href="https://www.oddcompany.xyz/"
            target="_blank"
            rel="noreferrer"
          >
            <li>oDD COMPANY</li>
          </a>
          <a
            href="https://www.notion.so/The-Hitchhiker-s-Guide-to-the-oDDverse-76e17a0b717043e998132940421fa122"
            target="_blank"
            rel="noreferrer"
          >
            <li>oDD paper</li>
          </a>

          <a
            href="https://docs.google.com/document/d/1HUnadZSNAVGMnfw75JhZUjOP3O22idq0e6z-aslkBk4/edit"
            target="_blank"
            rel="noreferrer"
          >
            <li>Terms</li>
          </a>
        </ul>

        <span className="text-[16px] my-7 font-bold">
          All rights reserved © Bryan Lashelle x Aatma Studio LLC = oDD COMPANY
          2023
        </span>

        <span className="text-[14px]">
          For inquiries contact hi@oddverse.xyz
        </span>
      </div>
    </footer>
  );
};

export default Footer;
