import { useWeb3Modal } from "@web3modal/react";
import { Link, NavLink } from "react-router-dom";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { addressShortener } from "../../utils";
import logo from "../../assets/Images/oddVerse-logo.svg";
const Header = () => {
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { address, isConnected } = useAccount();

  const connectWallet = () => {
    if (chain?.id !== 1) {
      switchNetwork?.(1);
    }
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <header className="w-full flex border-b border-[#fff] bg-black justify-center pt-1 px-1">
      <div className="max-w-screen-2xl w-full p-2 rounded-xl">
        {/* Desktop header */}
        <div className="flex flex-wrap gap-3 justify-between items-center">
          <ul className="flex text-white gap-7 text-2xl">
            <a
              href="https://www.oddverse.xyz/"
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <img
                  src={logo}
                  alt="logo"
                  className="w-32"
                />
              </li>
            </a>
            {/* <Link to={"/checkWallet"}>
              <li>Check Wallet</li>
            </Link>

            <Link to="/mint">
              <li>Mint</li>
            </Link>

            <Link to={"/stake"}>
              <li>Stake</li>
            </Link> */}
          </ul>

          {isConnected ? (
            <button
              className="bg-[#FFB800] p-3 rounded-[12px] rounded-tl-none"
              onClick={connectWallet}
            >
              {addressShortener(address)}
            </button>
          ) : (
            <div>
              <div className="hidden md:flex">
                <button
                  className="bg-[#FFB800] p-3 rounded-[12px] rounded-tl-none"
                  onClick={connectWallet}
                >
                  Connect Wallet
                </button>
              </div>
              <div className="md:hidden">
                <button
                  className="bg-[#FFB800] p-3 rounded-[12px] rounded-tl-none"
                  onClick={connectWallet}
                >
                  Connect
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
